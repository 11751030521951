// App
import React, { FC } from 'react';

// Icons
import IconStarYellow from 'images/icons/star-yellow.min.svg';
import IconStarOutlineYellow from 'images/icons/star-outline-yellow.min.svg';

// Misc / utils
import * as S from './styles';

interface StaticStarRatingProps {
    totalStars: number;
    filledStars: number;
    size?: 'sm' | 'md' | 'lg';
    className?: string;
}

const StaticStarRating: FC<StaticStarRatingProps> = ({ totalStars, filledStars, size = 'md', className }) => {
    const starArr = new Array(totalStars).fill('*');

    return (
        <span role="img" aria-label={`Rating: ${filledStars} out of 5 stars`} className={className}>
            {starArr.map((_, idx) => {
                if (idx < Math.round(filledStars)) {
                    return (
                        <S.StarImg
                            alt=""
                            aria-hidden="true"
                            key={`filled-star-number-${idx}`}
                            src={IconStarYellow}
                            height={15}
                            width={15}
                            size={size}
                        />
                    );
                }

                return (
                    <S.StarImg
                        alt=""
                        aria-hidden="true"
                        key={`outline-star-number-${idx}`}
                        src={IconStarOutlineYellow}
                        height={15}
                        width={15}
                        size={size}
                    />
                );
            })}
        </span>
    );
};

export { StaticStarRating };
export default StaticStarRating;