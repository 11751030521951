// @ts-nocheck
/* eslint-enable */
import React from 'react';
import ListingSquare from 'app/shared/modules/listing-cards/ListingSquare';

import './ListingSquareList.scss';

class ListingSquareList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { listings, clickCb } = this.props;

        return (
            <div className="ListingSquareList">
                {listings.map((listing) => {
                    return (
                        <div
                            className="ListingSquareList-item"
                            key={listing.aliasEncoded || listing.maloneLotIdEncoded}
                        >
                            <ListingSquare listing={listing} clickCb={clickCb} />
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default ListingSquareList;
