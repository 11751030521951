// @ts-nocheck
/* eslint-enable */
import React from 'react';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { stacked, margin } from 'app/shared/styles/_spacing';

export const SkeletonCarousel = styled.article`
    display: flex;
    height: 104px;
    margin-bottom: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: px;
    background: ${colors['$hpx-grey-100']};
`;

const SkeletonPagination = styled.ul`
    display: flex;
    width: 100%;
    height: 29px;
    justify-content: center;
`;

const SkeletonDots = styled.span`
    margin: 16px 4px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: ${colors['$hpx-grey-400']};
`;


export const SkeletonLine = styled.div`
    ${stacked._1x};
    ${margin.top._3x};
    width: 100%;
    background: ${colors['$hpx-grey-100']};
    height: 20px;
    border-radius: 3px;


    ${(props) =>
        props.width &&
        `
        width: ${props.width}
    `};
`;

const SkeletonHomeHubRow = () => {
    return (
        <>
            <SkeletonLine width="25%" />
            <SkeletonCarousel />
            <SkeletonPagination>
                <SkeletonDots />
                <SkeletonDots />
                <SkeletonDots />
                <SkeletonDots />
                <SkeletonDots />
            </SkeletonPagination>
        </>
    );
};

export default SkeletonHomeHubRow;