// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { yieldCallback } from '@zillow/yield-callback';
import { textOverflow } from 'app/shared/styles/_mixins';
import { colors } from 'app/shared/styles/_colors';

import IconAlert from 'images/icons/alert-light-teal.svg';
import RouteActions from 'app/shared/flux/actions/RouteActions';
import './style.scss';
import Text from 'app/shared/core/Text';

const StyledAlertNameText = styled(Text)`
    ${textOverflow};
`;
const StyledFilterSummaryText = styled(Text)`
    color: ${colors['$hpx-white']};
    fill: ${colors['$hpx-white']};
    font-weight: bold;
`;
class ResumeSearchCard extends Component {
    yieldTransitionTo = yieldCallback(() => {
        const { dispatch, url } = this.props;
        dispatch(RouteActions.transitionToUrlWithFilterQuery(url));
    });

    handleClick = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        }

        this.yieldTransitionTo();
    };
    render() {
        const { name, filterSummary, numOfNewResults } = this.props;
        return (
            <div className="ResumeSearchCard" onClick={this.handleClick}>
                <div className="ResumeSearchCard-left-col">
                    <StyledAlertNameText htmlTag="div" size="sm" className="ResumeSearchCard-alert-name">
                        {name}
                    </StyledAlertNameText>
                    <StyledFilterSummaryText htmlTag="div" size="tiny" className="ResumeSearchCard-filter-summary">
                        {filterSummary}
                    </StyledFilterSummaryText>
                </div>
                <div className="ResumeSearchCard-right-col">
                    <Text htmlTag="div" size="tiny" className="ResumeSearchCard-new-results">
                        <img src={IconAlert} height="16" width="16" className="ResumeSearchCard-alert-icon" alt="" />
                        {numOfNewResults} new
                    </Text>
                    <button className="ResumeSearchCard-button">Resume</button>
                </div>
            </div>
        );
    }
}

export default connect()(ResumeSearchCard);
