import styled from 'styled-components';

interface StarImgProps {
    size?: 'sm' | 'md' | 'lg';
}

export const StarImg = styled.img<StarImgProps>`
    display: inline-block;
    margin-right: 4px;
    color: inherit;

    &:last-child {
        margin-right: 0;
    }

    ${(props) =>
        props.size === 'sm' &&
        `
        height: 10px;
        width: 10px;
    `}

    ${(props) =>
        props.size === 'md' &&
        `
        height: 13px;
        width: 13px;
    `}

    ${(props) =>
        props.size === 'lg' &&
        `
        height: 16px;
        width: 16px;
        margin-right: 8px;
    `}
`;