// @ts-nocheck
/* eslint-enable */
import React from 'react';
import Head from 'react-helmet';

/**
 * WebSite
 * Preferred name you want Google Search to display in Search results.
 * @see https://developers.google.com/search/docs/data-types/sitename
 *
 * Organization/Logo
 * The image Google Search uses for your organization's logo in Search results and in the Knowledge Graph.
 * @see https://developers.google.com/search/docs/data-types/logo
 */

const WebSiteAndOrganization = () => {
    const COMPANY_NAME = 'HotPads';
    const COMPANY_ALTERNATE_NAME = 'HotPads.com';
    const COMPANY_URL = 'https://hotpads.com';

    return (
        <Head
            script={[
                {
                    type: 'application/ld+json',
                    innerHTML: JSON.stringify({
                        '@context': 'http://schema.org',
                        '@type': 'WebSite',
                        name: COMPANY_NAME,
                        alternateName: COMPANY_ALTERNATE_NAME,
                        url: COMPANY_URL,
                        hasPart: {
                            '@type': 'WebPage',
                            url: COMPANY_URL,
                            name: COMPANY_NAME
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: COMPANY_NAME,
                            alternateName: COMPANY_ALTERNATE_NAME,
                            logo: 'https://filenet.hotpads.com/images/logos/logo_square.png',
                            sameAs: [
                                'https://www.facebook.com/hotpads/',
                                'https://www.linkedin.com/company/hotpads-com',
                                'https://twitter.com/hotpads',
                                'https://www.instagram.com/hotpads/'
                            ],
                            brand: {
                                '@type': 'Thing',
                                name: COMPANY_NAME
                            },
                            address: {
                                '@type': 'PostalAddress',
                                streetAddress: '535 Mission Street, Suite 700',
                                addressLocality: 'San Francisco',
                                addressRegion: 'CA',
                                postalCode: '94105',
                                addressCountry: {
                                    '@type': 'Country',
                                    name: 'US'
                                }
                            },
                            parentOrganization: {
                                '@type': 'Corporation',
                                url: 'https://www.zillowgroup.com/',
                                name: 'Zillow Group',
                                tickerSymbol: 'Z',
                                legalName: 'Zillow Group, Inc.',
                                sameAs: [
                                    'https://www.wikidata.org/wiki/Q8071921',
                                    'https://en.wikipedia.org/wiki/Zillow',
                                    'https://www.linkedin.com/company/zillow-group/',
                                    'https://www.facebook.com/Zillow/',
                                    'https://twitter.com/ZillowGroup',
                                    'https://www.instagram.com/zillow'
                                ],
                                address: {
                                    '@type': 'PostalAddress',
                                    streetAddress: '1301 Second Avenue, Floor 31',
                                    addressLocality: 'Seattle',
                                    addressRegion: 'WA',
                                    postalCode: '98101',
                                    addressCountry: 'US'
                                }
                            }
                        }
                    })
                }
            ]}
        />
    );
};

export default WebSiteAndOrganization;
