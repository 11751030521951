// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import assign from 'lodash/assign';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import imageError from 'images/imageError.png';

class ImageLoader extends Component {
    static propTypes = {
        onClick: PropTypes.func,
        className: PropTypes.string,
        altText: PropTypes.string,
        image: PropTypes.string,
        titleText: PropTypes.string,
        fetchPriority: PropTypes.string
    };

    static defaultProps = {
        onClick() { },
        className: null,
        altText: '',
        image: '',
        titleText: '',
        fetchPriority: 'auto'
    };

    constructor(props) {
        super(props);
        const { image } = this.props;
        this.state = {
            src: image,
            hasUsedFallback: false
        };

        this.img = null;
    }

    componentDidMount() {
        /**
         * On SSR, images are not loaded so `onError` will never be called
         * Use this.loadImage to check if there was an error loading the image
         */
        const { isInitialSsrPage } = this.props;
        if (isInitialSsrPage) {
            this.loadImage();
        }
    }

    componentDidUpdate(nextProps) {
        const { image } = this.props;
        if (image !== nextProps.image) {
            this.loadImage();
        }
    }

    componentWillUnmount() {
        // Reset this.img object to prevent asynchronous `onload` function call.
        if (this.img) {
            this.img.onload = () => { };
            this.img = null;
        }
    }

    loadImage = () => {
        const { image } = this.props;
        this.img = new Image();

        this.img.src = image;
        this.img.onerror = this.handleError;
        this.img.onload = () => this.setState({ src: image });
    };

    handleError = () => {
        const { hasUsedFallback } = this.state;
        if (!hasUsedFallback) {
            this.setState({
                src: imageError,
                hasUsedFallback: true
            });
        }
    };

    mergeStyles = (src) => {
        return assign(
            {
                backgroundImage: `url(${src})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                width: '100%',
                height: '100%'
            },
            this.props.style
        );
    };

    render() {
        let { onClick, className, altText, fetchPriority, titleText, style } = this.props;
        let { src } = this.state;

        return (
            <img
                className={cx('ImageLoader', className)}
                src={src}
                onClick={onClick}
                onError={this.handleError}
                alt={altText}
                title={titleText}
                style={style}
                fetchpriority={fetchPriority}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    isInitialSsrPage: state.app.isInitialSsrPage
});

export default connect(mapStateToProps)(ImageLoader);
