// @ts-nocheck
/* eslint-enable */
import React from 'react';
import styled from 'styled-components';
import { textOverflow } from 'app/shared/styles/_mixins';
import ImageLoader from 'app/shared/modules/photo-gallery/ImageLoader';
import Linker from 'app/shared/modules/Linker';
import Text from 'app/shared/core/Text';
import './ListingSquare.scss';

const StyledTextWithOverflow = styled(Text)`
    ${textOverflow};
`;
const StyledDisplayName = styled(StyledTextWithOverflow)`
    font-weight: bold;
`;
class ListingSquare extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { clickCb, listing } = this.props;

        return (
            <Linker to={listing.uriV2} onClick={clickCb}>
                <div className="ListingSquare">
                    <div className="ListingSquare-photo-container">
                        <div className="ListingSquare-darken" />
                        <ImageLoader
                            className="ListingSquare-photo"
                            altText={`${listing.displayName} Photo 1`}
                            titleText={`${listing.displayName} Photo 1`}
                            image={listing.previewPhoto.url}
                        />
                    </div>
                    <div className="ListingSquare-text">
                        <StyledDisplayName
                            htmlTag="div"
                            responsive={{ smAndUp: 'md', mdAndUp: 'md' }}
                        >
                            {listing.displayName}
                        </StyledDisplayName>
                        {!listing.address.hideAddress && (
                            <StyledTextWithOverflow htmlTag="div" className="ListingSquare-street" size="sm">
                                {listing.address.street}, {listing.address.city}, {listing.address.state}
                            </StyledTextWithOverflow>
                        )}
                    </div>
                </div>
            </Linker>
        );
    }
}

ListingSquare.defaultProps = {
    clickCb: () => { }
};

export default ListingSquare;
