// @ts-nocheck
/* eslint-enable */
import React from 'react';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { stacked, margin } from 'app/shared/styles/_spacing';

const SkeletonCard = styled.article`
    ${stacked._5x};
    display: flex;
    height: 104px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    background: ${colors['$hpx-grey-100']};
    ${(props) =>
        props.marginTop &&
        `
        margin-top: ${props.marginTop}
    `};

    ${(props) =>
        props.marginBottom &&
        `
        margin-bottom: ${props.marginBottom}
    `};
`;

const SkeletonTitle = styled.div`
    width: 100%;
    background: ${colors['$hpx-grey-100']};
    height: 20px;
    border-radius: 3px;

    ${(props) =>
        props.height &&
        `
        height: ${props.height}
    `};

    ${(props) =>
        props.marginTop &&
        `
        margin-top: ${props.marginTop}
    `};

    ${(props) =>
        props.marginBottom &&
        `
        margin-bottom: ${props.marginBottom}
    `};
`;

export const SkeletonLine = styled.div`
    ${stacked._1x};
    ${margin.top._3x};
    width: 100%;
    background: ${colors['$hpx-grey-100']};
    height: 20px;
    border-radius: 3px;

    ${(props) =>
        props.width &&
        `
        width: ${props.width}
    `};
`;

export const SkeletonSearchAreasContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 14px 0 14px 0;

    ${(props) =>
        props.marginTop &&
        `
        margin-top: ${props.marginTop}
    `};

    ${(props) =>
        props.marginBottom &&
        `
        margin-bottom: ${props.marginBottom}
    `};
`;

export const SkeletonSearchArea = styled.div`
    height: 40px;
    min-width: 150px;
    margin-right: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    background: ${colors['$hpx-grey-100']};
    ${(props) =>
        props.width &&
        `
        width: ${props.width}
    `};
`;

const SkeletonHomeHubAside = ({ isMobile }) => {
    return isMobile ? (
        <>
            <SkeletonTitle height="20px" marginTop={'24px'} marginBottom={'8px'} />
            <SkeletonCard marginBottom={'0px'} />
            <SkeletonTitle height="20px" marginTop={'24px'} marginBottom={'8px'} />
            <SkeletonSearchAreasContainer>
                <SkeletonSearchArea width={'120px'} />
                <SkeletonSearchArea />
                <SkeletonSearchArea width={'210px'} />
                <SkeletonSearchArea width={'200px'} />
                <SkeletonSearchArea />
            </SkeletonSearchAreasContainer>
        </>
    ) : (
        <>
            <SkeletonTitle height="28px" />
            <hr />
            <SkeletonLine width="25%" />
            <SkeletonCard />
            <SkeletonTitle height="28px" />
            <hr />
            <SkeletonLine width="25%" />
            <SkeletonCard />
        </>
    );
};

export default SkeletonHomeHubAside;
