import { useState } from 'react';
import { listingUtils_checkFavorite } from 'app/shared/utils/listingUtils';
import { ListingType } from 'app/types';

interface UseFavoriteButtonAnimationProps {
    listing: ListingType;
    isLoggedIn: boolean;
}

const useFavoriteButtonAnimation = ({ listing, isLoggedIn }: UseFavoriteButtonAnimationProps) => {
    const [pulse, setPulse] = useState<number>(0);
    const isFavorited = listingUtils_checkFavorite(listing);

    const handleAnimation = () => {
        const shouldAnimate = isLoggedIn && !isFavorited && pulse === 0;
        if (shouldAnimate) {
            setPulse(1);
        } else {
            setPulse(0);
        }
    };

    return [handleAnimation, pulse] as const;
};

export default useFavoriteButtonAnimation;