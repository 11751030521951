// @ts-nocheck
/* eslint-enable */
import React, { useEffect, useRef } from 'react';

const ImpressionTracking = (props) => {
    const impressionRef = useRef(null);

    useEffect(() => {
        const { current } = impressionRef;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.intersectionRatio === 1 || entry.isIntersecting) {
                    props.analyticsCallback();
                    observer.unobserve(entry.target);
                }
            },
            {
                root: null,
                threshold: 1
            }
        );

        observer.observe(current);

        return () => {
            observer.disconnect(current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div ref={impressionRef} />;
};

export default ImpressionTracking;
