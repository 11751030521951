// @ts-nocheck
/* eslint-enable */
// Aoo
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useYieldCallback } from '@zillow/yield-callback';

// Components
import Linker from 'app/shared/modules/Linker';

// Misc / utils
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { font } from 'app/shared/styles/_fontsize';

const StyledLinker = styled(Linker)`
    ${font.sm};
    margin: 0 8px;
    line-height: 28px;
`;

const StyledUl = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const StyledLi = styled.li`
    display: inline;
`;

const CrossbrandFooter = () => {
    const dispatch = useDispatch();

    const yieldTrack = useYieldCallback((trackerName) => {
        dispatch(
            analyticsEvent(gaEvents.FOOTER_NAV_CLICK_TO, {
                action: trackerName
            })
        );
    });

    const trackFooterLinkClick = (event) => {
        const trackerName = event.target.getAttribute('data-footer-page');
        yieldTrack(trackerName);
    };

    return (
        <StyledUl>
            <StyledLi>
                <StyledLinker
                    className="CrossbrandFooter-link"
                    linkType="inverted"
                    data-footer-page="Zillow-Crossbrand-Footer"
                    onClick={trackFooterLinkClick}
                    target="_blank"
                    rel="noopener"
                    to="https://www.zillowgroup.com/"
                >
                    Zillow Group® Brands:
                </StyledLinker>
            </StyledLi>
            <StyledLi>
                <StyledLinker
                    className="CrossbrandFooter-link"
                    linkType="inverted"
                    data-footer-page="Zillow-Crossbrand-Footer"
                    onClick={trackFooterLinkClick}
                    target="_blank"
                    rel="noopener"
                    to="https://www.zillow.com/"
                >
                    Zillow
                </StyledLinker>
            </StyledLi>
            <StyledLi>
                <StyledLinker
                    className="CrossbrandFooter-link"
                    linkType="inverted"
                    data-footer-page="Trulia-Crossbrand-Footer"
                    onClick={trackFooterLinkClick}
                    target="_blank"
                    rel="noopener"
                    to="https://www.trulia.com/"
                >
                    Trulia
                </StyledLinker>
            </StyledLi>
            <StyledLi>
                <StyledLinker
                    className="CrossbrandFooter-link"
                    linkType="inverted"
                    data-footer-page="StreetEasy-Crossbrand-Footer"
                    onClick={trackFooterLinkClick}
                    target="_blank"
                    rel="noopener"
                    to="https://streeteasy.com/"
                >
                    StreetEasy
                </StyledLinker>
            </StyledLi>
            <StyledLi>
                <StyledLinker
                    className="CrossbrandFooter-link"
                    linkType="inverted"
                    data-footer-page="OutEast-Crossbrand-Footer"
                    onClick={trackFooterLinkClick}
                    target="_blank"
                    rel="noopener"
                    to="https://www.outeast.com/"
                >
                    Out East
                </StyledLinker>
            </StyledLi>
        </StyledUl>
    );
};

export default CrossbrandFooter;
