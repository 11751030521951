import { ClickstreamEvent } from 'app/types';
import { setPropertyInfoBlock } from './contextual-blocks/PropertyInfoBlock';
import { Envelope } from './Envelope';

// TODO: Eventually remove these comments
// Comment explains where this trigger location is currently being called from for debugging purposes.
type PropertyCardTriggerLocation =
    | 'accepts_application_collection' // ListingCollection
    | 'affordable_apartments_collection' // ListingCollection
    | 'building_unit_card' // BuildingHdp
    | 'luxury_apartments_collection' // ListingCollection
    | 'popular_apartments_collection' // ListingCollection
    | 'portfolio_listings_module' // PortfolioProperties
    | 'promo_apartments_collection' // ListingCollection
    | 'recent_search_collection' // ListingCollection
    | 'recently_viewed_listings_module' // RecentlyViewed
    | 'recommended_search_collection' //CollectionResultsPage/RecommendedApartments
    | 'saved_search_collection' // ListingCollection
    | 'search_results_list' // MobileSRP/ListingCards / AreaListingsContainer <-- listings containers on mWeb and desktop
    | 'search_results_map' //  SlidingPreviewContainer <-- MWeb map view
    | 'similar_listings_module' // SimilarListingsContainer
    | 'similar_to_contacted_collection' // ListingCollection
    | 'similar_to_favorite_collection'; // ListingCollection

export const TrackImpressionForPropertyCard = ({
    listing,
    triggerLocation
}: {
    // @ts-ignore TODO: Proper type definition for listings
    listing: any;
    triggerLocation: PropertyCardTriggerLocation;
}): ClickstreamEvent => {
    // Do not get listing from Redux store, as listing will be passed in from property card itself.

    const csPayload: ClickstreamEvent = {
        envelope: new Envelope({
            eventId: '5601',
            eventVersion: '1',
            templateId: '4',
            templateVersion: '1'
        }),
        clickstream_trigger: {
            trigger_type_nm: 'impression',
            trigger_location_nm: triggerLocation,
            trigger_source_nm: 'property_card',
            trigger_object_nm: 'no_trigger_object'
        },
        semantic: {
            semantic_event_nm: 'view_content'
        }
    };

    if (listing) {
        csPayload.property_info = setPropertyInfoBlock(listing);
    }

    return csPayload;
};
