// @ts-nocheck
/* eslint-enable */
// App
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import VisuallyHidden from 'app/shared/modules/VisuallyHidden';

// Hooks
import useFavoriteButtonAnimation from 'app/shared/modules/FavoriteButton/useFavoriteButtonAnimation';

// Misc / utils
import IconHeartFilledV3 from 'images/icons/save-icon-filled.svg';
import IconHeartDarkFilledV3 from 'images/icons/save-icon-dark-filled.svg';
import IconHeart from 'images/icons/heart-outline-white.min.svg';
import IconHeartFilled from 'images/icons/heart-fill-gold.min.svg';
import { getFavoriteButtonCX, getHeartIconAndAnimationCX } from './styles';

const FavoriteButton = ({
    onClick = () => { },
    listing = {},
    isBiggerListingCard = false,
    alreadyFavorited = false,
    size = '24px'
}) => {
    // Redux
    const isLoggedIn = useSelector((state) => state.user.loggedIn) || false;

    // React
    const [handleAnimation, pulse] = useFavoriteButtonAnimation({ listing, isLoggedIn });

    const handleFavoriteSelect = useCallback(
        (e) => {
            onClick(e);
            handleAnimation();
        },
        [handleAnimation, onClick]
    );

    if (!listing) {
        return null;
    }

    const heartIcon = isBiggerListingCard ? IconHeartDarkFilledV3 : IconHeart;
    const heartIconFilled = isBiggerListingCard ? IconHeartFilledV3 : IconHeartFilled;

    return (
        <button
            aria-label={`Add ${listing.displayName} to favorites`}
            aria-pressed={alreadyFavorited}
            className={getFavoriteButtonCX(isBiggerListingCard)}
            data-name="FavoriteButton"
            onClick={handleFavoriteSelect}
        >
            <img
                src={alreadyFavorited ? heartIconFilled : heartIcon}
                onAnimationEnd={handleAnimation}
                className={getHeartIconAndAnimationCX(pulse)}
                data-name="HeartIcon"
                height={size}
                width={size}
                alt=""
            />
            <VisuallyHidden>Favorite button</VisuallyHidden>
        </button>
    );
};

FavoriteButton.propTypes = {
    alreadyFavorited: PropTypes.bool,
    listing: PropTypes.object.isRequired,
    onClick: PropTypes.func
};

export default FavoriteButton;
