// @ts-nocheck
/* eslint-enable */
import Head from 'react-helmet';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Actions
import ErrorActions from 'app/shared/flux/actions/ErrorActions';
import { FilterActions_handleReduxOnlyFilterChange } from 'app/shared/flux/actions/FilterActions';
import UserSearchActions from 'app/shared/flux/actions/UserSearchActions';
import { fetchCollectionData } from 'app/shared/flux/actions/HomeHubActions';

// Components
import FooterNav from 'app/shared/modules/navigation/footer/FooterNav';
import HomeHero from 'app/shared/modules/frontpage/HomeHero';
import HomeHub from 'app/shared/modules/HomeHub';
import PopularMfBuildings from 'app/shared/modules/frontpage/PopularMfBuildings';
import PreFooter from 'app/shared/modules/frontpage/PreFooter';
import TopCities from 'app/shared/modules/frontpage/TopCities';
import WebSiteAndOrganization from 'app/shared/modules/json-ld/WebSiteAndOrganization';

// Misc / Utils
import { analyticsEvent, pageView } from 'app/client/universal-analytics';
import { trackHomepageView } from 'app/client/tracking';
import { TrackViewHomepage } from 'app/shared/models/Clickstream/PageViewEvents';

const FrontPageContainer = styled.main`
    overflow-y: scroll;
    height: calc(100% - 56px);
`;
class FrontPage extends React.Component {
    constructor(props) {
        super(props);
        const { dispatch } = this.props;
        trackHomepageView();
        dispatch(
            pageView({
                newLaneEvent: TrackViewHomepage()
            })
        );
    }
    serverRouteWillMount(reactContext) {
        let { store } = reactContext;
        let dispatch = store.dispatch;

        const getRecentSearches = dispatch(UserSearchActions.getRecentSearches())
            .then((res = {}) => {
                const { filter } = res;

                if (!filter) {
                    return;
                }

                dispatch(FilterActions_handleReduxOnlyFilterChange(filter));
            })
            .catch((error) => {
                dispatch(
                    ErrorActions.errorHandler({
                        error,
                        errorLocation: 'component.frontPage.serverRouteWillMount#getRecentSearches',
                        errorClass: 'userSearchActions'
                    })
                );
            });
        const getSavedSearches = dispatch(UserSearchActions.getSavedSearches()).catch((error) => {
            dispatch(
                ErrorActions.errorHandler({
                    error,
                    errorLocation: 'component.frontPage.serverRouteWillMount#getSavedSearches',
                    errorClass: 'userSearchActions'
                })
            );
        });
        const getCollections = dispatch(fetchCollectionData()).catch((error) => {
            dispatch(
                ErrorActions.errorHandler({
                    error,
                    errorLocation: 'component.frontPage.serverRouteWillMount#getCollections',
                    errorClass: 'homeHubActions'
                })
            );
        });

        return [getRecentSearches, getSavedSearches, getCollections];
    }
    render() {
        const { hasGeolocation, hasRecentSearch } = this.props;

        return (
            <FrontPageContainer>
                <WebSiteAndOrganization />
                <Head
                    link={[
                        {
                            rel: 'canonical',
                            href: 'https://hotpads.com'
                        }
                    ]}
                    meta={[
                        {
                            name: 'description',
                            content:
                                'Browse the largest and most trusted apartment and home rental site with our' +
                                ' verified listings, fraud protection technology and real-time property updates.'
                        }
                    ]}
                    title="HotPads - Go ahead. Rent around. Apartments and Houses for Rent"
                />
                {hasGeolocation || hasRecentSearch ? (
                    <HomeHub />
                ) : (
                    <>
                        <HomeHero />
                        <PopularMfBuildings />
                        <TopCities />
                        <PreFooter />
                        <FooterNav crossbrand />
                    </>
                )}
            </FrontPageContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        hasGeolocation: !isEmpty(state.geolocation.area) && state.geolocation.area.resourceId !== 'united-states',
        hasRecentSearch: !isEmpty(state.user.search.recent)
    };
};

export default connect(mapStateToProps)(FrontPage);
