// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { yieldCallback } from '@zillow/yield-callback';
import styled from 'styled-components';
import IconSearch from 'images/icons/search.svg';

const StyledButtonWrapper = styled.a`
    height: 40px;
`;
export default class ExpandSearchButton extends Component {
    yieldTransitionTo = yieldCallback(() => {
        const { url, areaSelection } = this.props;

        areaSelection();

        window.router.transitionTo(url);
    });

    handleOnClick = (e) => {
        // Prevent the browser from going through with its default <a> behavior
        e.preventDefault();

        this.yieldTransitionTo();
    };
    render() {
        const { name, url, areaSelection } = this.props;

        return (
            <StyledButtonWrapper href={url} onClick={this.handleOnClick}>
                <img src={IconSearch} height="16px" width="16px" alt="" />
                <span>{name}</span>
            </StyledButtonWrapper>
        );
    }
}
