// @ts-nocheck
/* eslint-enable */
import { FloodlightTracker } from './floodlight';
import { FacebookPixel } from './facebook';
import { Adwords } from './adwords';
import bing from 'app/client/bing';

export function trackForRentContact(listing) {
    FloodlightTracker.trackForRentContact(listing);
    FacebookPixel.trackForRentContact(listing);
    Adwords.trackForRentContact(listing);
    bing.trackForRentContact(listing);
}

export function trackForRentPhoneContact(partialListing) {
    FloodlightTracker.trackForRentPhoneContact(partialListing);
    FacebookPixel.trackForRentPhoneContact(partialListing);
    Adwords.trackForRentPhoneContact(partialListing);
    bing.trackForRentPhoneContact(partialListing);
}

export function trackPayPerClick(listing) {
    FloodlightTracker.trackPayPerClick(listing);
    FacebookPixel.trackPayPerClick(listing);
    Adwords.trackPayPerClick(listing);
    bing.trackPayPerClick(listing);
}

export function trackListingView(listing) {
    FloodlightTracker.trackListingView(listing);
    FacebookPixel.trackListingView(listing);
}

export function trackHomepageView() {
    FloodlightTracker.trackHomepageView();
    FacebookPixel.trackHomepageView();
}
