// @ts-nocheck
/* eslint-enable */
/* eslint-disable camelcase, dot-notation */


const logger = getLogger('adwords/events');

function conversion(conversionLabel, url) {
    if (!conversionLabel) {
        return;
    }

    if (window) {
        let w = window;
        w.google_conversion_id = 848246526;
        w.google_conversion_label = conversionLabel;
        w.google_remarketing_only = false;

        w.google_conversion_format = '3';
        let opt = {};
        opt.onload_callback = function() {
            if (typeof url !== 'undefined') {
                window.location = url;
            }
        };

        let conv_handler = w['google_trackConversion'];
        if (typeof conv_handler === 'function') {
            conv_handler(opt);
        }
    }
    logger.debug({ conversionLabel, url }, 'google adwords conversion');
}

export default {
    trackForRentContact({ trusted, isApartmentBldg } = {}) {
        if (trusted) {
            if (isApartmentBldg) {
                conversion('ux1QCMWOucgDEP7tvJQD');
            } else {
                conversion('GGbbCPuCsHIQ_u28lAM');
            }
        } else if (!trusted && isApartmentBldg) {
            conversion('foAwCJbdtHMQ_u28lAM');
        } else {
            conversion('YypeCKOCyHIQ_u28lAM');
        }
    },

    trackForRentPhoneContact({ trusted, isApartmentBldg } = {}) {
        if (isApartmentBldg) {
            // Paid MF Calls
            conversion('9ldvCJG_rn0Q_u28lAM');
        } else if (trusted) {
            // Paid Non-MF For Rent Calls
            conversion('fR0ACNTGwX0Q_u28lAM');
        } else {
            // Unpaid For Rent Calls
            conversion('kWTdCMjGwX0Q_u28lAM');
        }
    },

    trackPayPerClick() {
        // CPC clicks
        conversion('pHZyCND1xX0Q_u28lAM');
    }
};
