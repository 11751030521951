// @ts-nocheck
/* eslint-enable */
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { textOverflow } from 'app/shared/styles/_mixins';
import { yieldCallback } from '@zillow/yield-callback';

import { analyticsEvent } from 'app/client/universal-analytics';
import Carousel from 'app/shared/modules/Carousel';
import Linker from 'app/shared/modules/Linker';
import ListingCard from './ListingCard';
import ResumeSearchCard from './ResumeSearchCard';
import Text from 'app/shared/core/Text';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import ImpressionTracking from 'app/shared/modules/ImpressionTracking';
import { impression } from 'app/client/zg-analytics';
import { TrackImpressionForPropertyCard } from 'app/shared/models/Clickstream/ListingCardClickstreamEvents';

const {
    HOMEHUB_LUXURY_APARTMENTS,
    HOMEHUB_CHEAP_APARTMENTS,
    HOMEHUB_RENTALS_WITH_PROMOS,
    HOMEHUB_SIMILAR_TO_FAVORITE,
    HOMEHUB_NEW_FROM_SAVED_SEARCH,
    HOMEHUB_SIMILAR_TO_CONTACTED,
    HOMEHUB_NEW_FROM_RECENT_SEARCH,
    HOMEHUB_POPULAR_APARTMENTS,
    HOMEHUB_RECOMMENDED_SEARCH,
    HOMEHUB_ACCEPTS_APPLICATION
} = gaEvents;

const analyticsMap = {
    savedSearch: HOMEHUB_NEW_FROM_SAVED_SEARCH,
    similarToFavorite: HOMEHUB_SIMILAR_TO_FAVORITE,
    similarToContacted: HOMEHUB_SIMILAR_TO_CONTACTED,
    recentSearch: HOMEHUB_NEW_FROM_RECENT_SEARCH,
    luxuryApartments: HOMEHUB_LUXURY_APARTMENTS,
    affordableApartments: HOMEHUB_CHEAP_APARTMENTS,
    promoApartments: HOMEHUB_RENTALS_WITH_PROMOS,
    popularApartments: HOMEHUB_POPULAR_APARTMENTS,
    recommendedSearch: HOMEHUB_RECOMMENDED_SEARCH,
    acceptsApplication: HOMEHUB_ACCEPTS_APPLICATION
};

const ClickstreamLocationMap = {
    savedSearch: 'saved_search_collection',
    similarToFavorite: 'similar_to_favorite_collection',
    similarToContacted: 'similar_to_contacted_collection',
    recentSearch: 'recent_search_collection',
    luxuryApartments: 'luxury_apartments_collection',
    affordableApartments: 'affordable_apartments_collection',
    promoApartments: 'promo_apartments_collection',
    popularApartments: 'popular_apartments_collection',
    recommendedSearch: 'recommended_search_collection',
    acceptsApplication: 'accepts_application_collection'
};

const StyledTitle = styled.h3`
    ${textOverflow};
`;
/**
 * generic class for collections of listings rendered inside a carousel
 */
class ListingCollection extends Component {
    static propTypes = {
        listings: PropTypes.object,
        title: PropTypes.string
    };

    handleSwipe = () => {
        this.sendAnalyticsEvent('Swipe');
    };

    handleClick = () => {
        this.sendAnalyticsEvent('Click');
    };

    sendAnalyticsEvent = (action) => {
        const { collectionType, dispatch } = this.props;
        const gaEvent = analyticsMap[collectionType];
        dispatch(analyticsEvent(gaEvent, { action }));
    };

    trackImpression = (listing) => {
        const { collectionType, dispatch } = this.props;
        const { aliasEncoded } = listing;

        const label = String(collectionType + 'Collection');
        const triggerLocation = ClickstreamLocationMap[collectionType];

        impression({
            category: 'RentalHomehub',
            label,
            dimension60: aliasEncoded
        });

        dispatch(analyticsEvent({ newLaneEvent: TrackImpressionForPropertyCard({ listing, triggerLocation }) }));
    };

    yieldHandleTransitionTo = yieldCallback(() => {
        const { url } = this.props;
        window.router.transitionTo(url);
    });

    handleSeeAllClick = (e) => {
        // Prevent the browser from going through with its default <a> behavior
        e.preventDefault();

        this.yieldHandleTransitionTo();
    };

    render() {
        const { title, listings, filter, url, collectionType, numOfNewResults, filterSummary, name } = this.props;
        const titleId = title.toLowerCase().replaceAll(' ', '-');

        if (isEmpty(listings)) {
            return null;
        }

        return (
            <React.Fragment>
                <div className="HomeHub-section-title-container">
                    <StyledTitle className="HomeHub-section-title" id={titleId}>
                        {title}
                    </StyledTitle>
                    {url && collectionType !== 'savedSearch' && (
                        <Linker
                            className="HomeHub-section-link"
                            to={url}
                            aria-describedby={titleId}
                            onClick={this.handleSeeAllClick}
                        >
                            <Text size="sm">See all</Text>
                        </Linker>
                    )}
                </div>
                <Carousel onSwipe={this.handleSwipe} titleText={title}>
                    {collectionType === 'savedSearch' && (
                        <div onClick={this.handleClick}>
                            <ResumeSearchCard
                                numOfNewResults={numOfNewResults}
                                url={url}
                                name={name}
                                filterSummary={filterSummary}
                            />
                        </div>
                    )}
                    {map(Object.entries(listings), (listing, idx) => {
                        const listingData = listing[1];

                        return (
                            <div className="ListingCollection-card-container" key={`${listing.aliasEncoded}-container`}>
                                <ListingCard
                                    key={listingData.aliasEncoded}
                                    listing={listingData}
                                    index={idx}
                                    filter={filter}
                                    onClick={this.handleClick}
                                    triggerLocation="my_hub_page"
                                    triggerObject="property_card"
                                />
                                <ImpressionTracking analyticsCallback={() => this.trackImpression(listingData)} />
                            </div>
                        );
                    })}
                </Carousel>
            </React.Fragment>
        );
    }
}

export default connect()(ListingCollection);
