// @ts-nocheck
/* eslint-enable */
import React from 'react';
import styled from 'styled-components';
import { Animate } from 'react-move';
import { yieldCallback } from '@zillow/yield-callback';
import { addEvent, removeEvent, getValidChildren, easeCircleOut } from './util';
import IconLeftArrow from 'images/icons/arrow-left-dark-grey.svg';
import IconRightArrow from 'images/icons/arrow-right-dark-grey.svg';
import ListItem from './ListItem';
import PagingDots from './PagingDots';
import PropTypes from 'prop-types';
import './style.scss';

const StyledVertHorizCenterButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
`;
class Carousel extends React.Component {
    static propTypes = {
        onSwipe: PropTypes.func,
        speed: PropTypes.number,
        titleText: PropTypes.string
    };

    static defaultProps = {
        onSwipe: () => { },
        speed: 500, // ms
        titleText: ''
    };

    constructor(props) {
        super(props);

        this.isTransitioning = false;
        this.listItemWidths = [];
        // NOTE: We currently mock this function with jest so that we can set viewport.offsetWidth
        //       as other calculations depend on that. So the "ref" function must be bound here.
        this.getViewportRef = this.getViewportRef.bind(this);
        this.state = {
            currentSlide: 0,
            slideCount: getValidChildren(this.props.children).length,
            slidesToScroll: 1
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const slideCount = getValidChildren(nextProps.children).length;

        if (slideCount !== prevState.slideCount) {
            return { slideCount };
        } else {
            return null;
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.setDimensions();
        this.bindEvents();
    }

    /* eslint-disable react/no-did-update-set-state */
    // TODO: Is there a better way to track the number of slides?
    componentDidUpdate(prevProps, prevState) {
        if (prevState.slideCount !== this.state.slideCount) {
            this.setDimensions({
                slideCount: this.state.slideCount
            });
        }
    }

    componentWillUnmount() {
        this.unbindEvents();
        this.mounted = false;
    }

    handleSwipePrevious = () => {
        const { currentSlide } = this.state;

        if (currentSlide > 0) {
            this.previousSlide();
        }
    };

    handleSwipeNext = () => {
        const { slidesToScroll, currentSlide, slideCount } = this.state;

        if (currentSlide < slideCount - slidesToScroll) {
            this.nextSlide();
        }
    };

    handleAnimationUpdate = () => {
        const { speed } = this.props;
        const { currentSlide } = this.state;

        let offset = 0;
        for (let index = 0; index < currentSlide; index++) {
            offset -= this.listItemWidths[index];
        }

        return {
            duration: speed,
            ease: easeCircleOut,
            tx: [offset],
            ty: [0],
            end: () => { }
        };
    };

    yieldSetSlideState = yieldCallback((index) => {
        const { onSwipe, speed } = this.props;
        onSwipe();
        this.setState(
            {
                currentSlide: index
            },
            () =>
                setTimeout(() => {
                    this.isTransitioning = false;
                }, speed)
        );
    });

    goToSlide = (index) => {
        const { slideCount } = this.state;

        if (this.isTransitioning) {
            return;
        }

        this.isTransitioning = true;

        if (index >= slideCount || index < 0) {
            this.isTransitioning = false;
            return;
        }
        this.yieldSetSlideState(index);
    };

    handleNextSlide = () => {
        const { slideCount, currentSlide, slidesToScroll } = this.state;

        if (currentSlide + slidesToScroll > slideCount) {
            return;
        }

        this.goToSlide(currentSlide + slidesToScroll);
    };

    handlePrevSlide = () => {
        const { currentSlide, slidesToScroll } = this.state;

        if (currentSlide <= 0) {
            return;
        }

        const nextSlideIndex = Math.max(0, currentSlide - slidesToScroll);

        this.goToSlide(nextSlideIndex);
    };

    bindEvents = () => {
        if (__CLIENT__) {
            addEvent(window, 'resize', this.onResize);
            addEvent(document, 'readystatechange', this.onReadyStateChange);
        }
    };

    unbindEvents = () => {
        if (__CLIENT__) {
            removeEvent(window, 'resize', this.onResize);
            removeEvent(document, 'readystatechange', this.onReadyStateChange);
        }
    };

    onResize = () => {
        this.setDimensions();
    };

    onReadyStateChange = () => {
        this.setDimensions();
    };

    setDimensions = (state = {}) => {
        const viewportWidth = this.viewport.offsetWidth;
        let totalWidth = 0;
        let slidesToScroll = 0;

        this.listItemWidths.forEach((width) => {
            totalWidth += width;
            if (totalWidth < viewportWidth) {
                slidesToScroll += 1;
            }
        });

        this.setState({
            ...state,
            slidesToScroll: slidesToScroll === 0 ? 1 : slidesToScroll
        });
    };

    // NOTE: This is here so that we can mock it with jest
    getViewportRef(e) {
        this.viewport = e;
    }

    // Each ListItem will call this so we can store individual widths for each ListItem index
    setListItemWidth = ({ index, width }) => {
        this.listItemWidths[index] = width;
    };

    getListItems = () => {
        const validChildren = getValidChildren(this.props.children);

        return React.Children.map(validChildren, (child, index) => {
            const { currentSlide, slidesToScroll } = this.state;
            const isInView = index >= currentSlide && index <= currentSlide + slidesToScroll;
            return (
                <ListItem
                    className="Carousel-item"
                    reportWidth={this.setListItemWidth}
                    key={index}
                    index={index}
                    hidden={!isInView}
                >
                    {child}
                </ListItem>
            );
        });
    };

    getListStyles = ({ deltaX }) => {
        const transform = `translate3d(${deltaX}px, 0, 0)`;

        return {
            transform,
            WebkitTransform: transform,
            msTransform: `translate(${deltaX}px, 0)`
        };
    };

    render() {
        const { currentSlide, slideCount, slidesToScroll } = this.state;
        const { titleText } = this.props;
        const listItems = this.getListItems();
        const disablePrev = currentSlide === 0 || slideCount === 0;
        const disableNext = currentSlide + slidesToScroll >= slideCount;

        return (
            <div className="Carousel">
                <div className="Carousel-viewport" ref={this.getViewportRef}>
                    {!disablePrev && (
                        <StyledVertHorizCenterButton
                            type="button"
                            className="Carousel-left-arrow"
                            onClick={this.handlePrevSlide}
                        >
                            <img src={IconLeftArrow} height="16px" width="16px" alt={`Previous ${titleText}`} />
                        </StyledVertHorizCenterButton>
                    )}
                    <Animate show start={{ tx: 0, ty: 0 }} update={this.handleAnimationUpdate()}>
                        {({ tx }) => {
                            return (
                                <ul className="Carousel-ul" style={this.getListStyles({ deltaX: tx })}>
                                    {listItems}
                                </ul>
                            );
                        }}
                    </Animate>
                    {!disableNext && (
                        <StyledVertHorizCenterButton
                            type="button"
                            className="Carousel-right-arrow"
                            onClick={this.handleNextSlide}
                        >
                            <img src={IconRightArrow} height="16px" width="16px" alt={`Next ${titleText}`} />
                        </StyledVertHorizCenterButton>
                    )}
                </div>

                <PagingDots
                    currentSlide={currentSlide}
                    goToSlide={this.goToSlide}
                    slideCount={slideCount}
                    slidesToScroll={slidesToScroll}
                    ariaLabel={`${titleText} Carousel`}
                />
            </div>
        );
    }
}

export default Carousel;
